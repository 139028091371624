<script setup>
import { ref, toRef, computed, watch } from 'vue';
import { v4 as uuidv4 } from 'uuid';

import { useCompletionSelection } from 'JIX/utils/completion.js';

import { __, __nx } from 'JIX/gettext.js';

const completionsId = `searchfield__completions-${uuidv4()}`;

defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    modelValue: {
        type: String,
        default: "",
    },
    completions: {
        type: Array,
        default: () => [],
    },
    searching: {
        type: Boolean,
        default: false,
    },
    rootClass: {
        type: String,
        default: undefined,
    },
});
const completions = toRef(props, 'completions');

const emit = defineEmits(['update:modelValue', 'confirm']);

// Template refs
const input = ref();

// Instance data
const hasFocus = ref(false);

const query = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

const isExpanded = computed(() => Boolean(hasFocus.value && props.modelValue));

const {
    selection,
    selectNext,
    selectPrev,
} = useCompletionSelection(completions);

watch(selection, () => scrollToSelection(), { flush: 'post' });

function scrollToSelection() {
    const offset = document.querySelector(`#searchfield__completions li:nth-child(${selection.value + 1})`)?.offsetTop;
    if (offset !== undefined) {
        document.getElementById('searchfield__completions').scrollTop = offset;
    }
}

defineExpose({
    blur() {
        hasFocus.value = false;
        input.value?.blur();
    },
});

</script>

<template>
    <div
        :class="['searchfield', { 'searchfield--focus': hasFocus }, rootClass]"
        role="combobox"
        :aria-expanded="isExpanded ? 'true' : 'false'"
        @focusin="hasFocus = true"
        @focusout="hasFocus = false"
    >
        <input
            v-bind="$attrs"
            class="form-control"
            ref="input"
            role="searchbox"
            type="text"
            v-model.trim="query"
            @keydown.enter.prevent="emit('confirm', $event, selection)"
            @keydown.down.prevent="selectNext"
            @keydown.up.prevent="selectPrev"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            aria-autocomplete="list"
            :aria-controls="completionsId"
        >
        <ul
            @mousedown.prevent
            class="searchfield__completions"
            :id="completionsId"
            role="listbox"
            v-if="isExpanded"
        >
            <li v-if="searching" class="searchfield__searching">
                <span class="spinner-grow spinner-grow-sm" />
            </li>
            <li
                v-if="!searching && !completions.length"
                class="searchfield__completionitem searchfield__completionitem--focus"
            >
                {{ __('Ingen resultater') }}
            </li>
            <li
                :class="{ 'searchfield__completionitem': true, 'searchfield__completionitem--focus': selection === index }"
                v-for="(item, index) in completions"
                @click.stop="emit('confirm', $event, index); hasFocus = false"
                :key="item.id ?? index"
            >
                <slot :item="item" />
            </li>
        </ul>
    </div>
</template>
